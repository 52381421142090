// .registration-div {
//   height: 600px;
//   background: url("../../assets/images/landing.webp");

//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center;
// }
.registration-form {
  margin-top: -800px;
  // background: url("../../assets/images/bg.jpeg");
  //   margin-top: -800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  //   height: 600px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 220;
  @media screen and (max-width: 820px) {
    padding: 10px;
  }
  .form {
    background: #fff;
    padding: 40px 50px;
    border-radius: 10px;
    border: solid 10px #7bd6f5;
    box-shadow: 0 0 15px 15px #9f9584;
    @media screen and (max-width: 820px) {
      padding: 10px;
    }
    p {
      font-size: 14px;
      color: rgb(80, 78, 78);
    }
    span {
      font-weight: bold;
      font-size: 16px;
      color: black;
    }
    h2 {
      margin-top: 40px;
    }
    input {
      width: 100%;
      padding: 5px 20px;
      border: solid 1px rgb(211, 209, 209);
      border-radius: 5px;
      font-family: SoraSB;
    }
    small {
      font-size: 8px;
    }
    h6 {
      margin-bottom: 0;
      margin-top: 40px;
    }
  }
  select {
    width: 100%;
    padding: 5px 20px;
    border: solid 1px rgb(211, 209, 209);
    border-radius: 5px;
    font-family: SoraSB;
    background: #fff;
  }
  a {
    color: #7bd6f5;
    font-weight: bold;
    text-decoration: none;
  }
  .accordion-item {
    border: none !important;
  }
  .progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px; /* Adjust height as needed */
    background-color: #ddd;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.button-57 {
  position: relative;
  overflow: hidden;
  border: 2px solid #dc727c;
  color: #18181a;
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  padding: 10px 30px;
  text-decoration: none;
  cursor: pointer;
  background: #fff;
  user-select: none;
  border-radius: 5px;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 20px;
  font-family: SoraSB;
}

.button-57 span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.button-57 span:last-child {
  color: white;
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 14px;
  line-height: 13px;
}

.button-57:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #dc727c;
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.button-57:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.button-57:hover span:last-child {
  transform: translateX(-50%) translateY(-100%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}
.rules {
  margin-top: 30px;
  li {
    font-size: 12px;
    margin-top: 5px;
  }
}
.file {
  h5 {
    @media screen and (max-width: 820px) {
      margin-top: 20px;
    }
  }
}
.form-container {
  position: relative;
  width: 100%;
}

.progress-bar-fill {
  background-color: #dc727c;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
}

.progress-bar-text {
  color: #fff !important;
  font-weight: bold;
  z-index: 1;
  font-size: 10px !important;
}
