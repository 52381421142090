.contestants {
  height: fit-content;
  img {
    margin-top: 20px;
    border-radius: 100%;
    height: 150px;
    width: 150px;
    object-fit: cover;
  }
  button {
    margin-top: 90px;
    margin-left: 20px;
  }
  h3 {
    margin-top: 70px;
    font-size: 20px;
    span {
      font-size: 30px;
    }
  }
}
.chart-container {
  height: 600px;
  @media screen and (max-width:820px){height:fit-content; margin-top:60px;overflow:hidden;}
}
.wallet-btn {
  color: #fff;
  background: #dc727c;
  padding: 5px 20px;
  border-radius: 10px;
  border: solid 4px #dc727c;
  font-size: 15px;
  font-family: soraB;
  margin-bottom: 10px;
  margin-top: 160px;
  &:hover {
    color: #dc727c;
    background: #fff;
    transition: 0.5s ease;
  }
}
