footer {
  background: #def2fa;
  //   padding-top: 30px;
  padding-bottom: 20px;
  margin-top: 190px;
  img {
    margin-top: -130px;
  }
  a {
    color: #000;
    text-decoration: none;
    font-size: 18px;
    &:hover {
      color: #dc727c;
    }
  }
}
.media-links {
  li {
    font-size: 50px !important;
  }
}
.footer-paragraph{
  @media screen and (max-width:820px){
    text-align: center;
  }
}