.chart-container {
  // display: flex;
  align-items: flex-end;
  justify-content: space-around;
  // width: 100%;
  position: relative;
}

.chart-bar {
  width: 10%;
  background-color: #4caf50;
  transition: transform 0.5s ease; /* Smooth horizontal transition */
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.chart-label {
  margin-top: 5px;
  text-align: center;
  position: absolute;
  bottom: -20px; /* Adjust to position the label */
  width: 100%;
  font-size: 14px;
}
