.app-btn {
  color: #fff;
  background: #dc727c;
  padding: 5px 30px;
  border-radius: 10px;
  border: solid 4px #dc727c;
  font-size: 14px;
  font-family: soraM;
  &:hover {
    color: #dc727c;
    background: #fff;
    transition: 0.5s ease;
  }
}

.button-92 {
  --c: #fff;

  background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%)
      var(--_p, 100%) / 300% no-repeat,
    #dc727c;

  color: #0000;
  border: none;
  transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
  text-shadow: calc(var(--_i, -1) * 0.08em) -0.01em 0 var(--c),
    calc(var(--_i, -1) * -0.08em) 0.01em 2px #0004;
  outline-offset: 0.1em;
  transition: 0.3s;
}

.button-92:hover,
.button-92:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.button-92:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9Q #0005;
  transition: 0s;
}

.button-92 {
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
  cursor: pointer;
  padding: 0.1em 0.3em;
}

.button-48 {
  appearance: none;
  background-color: #ffffff;
  border-width: 0;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  outline: 0;
  padding: 1.5em 4px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-rendering: geometricprecision;
  text-transform: uppercase;
  transition: opacity 300ms cubic-bezier(0.694, 0, 0.335, 1),
    background-color 100ms cubic-bezier(0.694, 0, 0.335, 1),
    color 100ms cubic-bezier(0.694, 0, 0.335, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.button-48:before {
  animation: opacityFallbackOut 0.5s step-end forwards;
  backface-visibility: hidden;
  background-color: #ebebeb;
  clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  transition: clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
}

.button-48:hover:before {
  animation: opacityFallbackIn 0s step-start forwards;
  clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
}

.button-48:after {
  background-color: #ffffff;
}

.button-48 span {
  z-index: 1;
  position: relative;
}
@font-face {
  font-family: SoraM;
  src: url(./assets/fonts/sora/Sora-Medium.ttf);
}
@font-face {
  font-family: nick;
  src: url(./assets/fonts/nick/Nickainley-Normal.ttf);
}
@font-face {
  font-family: SoraB;
  src: url(./assets/fonts/sora/Sora-Bold.ttf);
}
@font-face {
  font-family: SoraEB;
  src: url(./assets/fonts/sora/Sora-ExtraBold.ttf);
}
@font-face {
  font-family: SoraR;
  src: url(./assets/fonts/sora/Sora-Regular.ttf);
}
@font-face {
  font-family: SoraSB;
  src: url(./assets/fonts/sora/Sora-SemiBold.ttf);
}
@font-face {
  font-family: SoraL;
  src: url(./assets/fonts/sora/Sora-Light.ttf);
}
@font-face {
  font-family: SerifR;
  src: url(./assets/fonts/serif/DMSerifDisplay-Regular.ttf);
}
@font-face {
  font-family: SerifI;
  src: url(./assets/fonts/serif/DMSerifDisplay-Italic.ttf);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: SerifR;
}

p,
li,
a,
small {
  font-family: SoraR;
}
button {
  font-family: SoraSB;
}
.mobile-padding {
  @media screen and (max-width: 820px) {
    padding-right: 7px;
    padding-left: 7px;
  }
}
.top-icon {
  background: #d7514e;
  border: solid 1px #d7514e;
  height: 30px;
  width: 30px;
  position: fixed;
  bottom: 0;
  border-radius: 5px;
  left: 50px;
  @media screen and (max-width: 820px) {
    // left: 20px;
  }
}
@media screen and (max-width: 820px) {
  .mobile-padding {
    padding-right: 7px;
    padding-left: 7px;
  }
}

.img-adj {
  width: 80%;
  @media screen and (max-width: 820px) {
    width: 100%;
  }
}
.hide {
  display: none !important;
}
.dash-layout{
  padding:0 20px;
  @media screen and (max-width: 820px) {
    padding:0 5px;
  }
}