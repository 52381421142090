/* src/components/ProgressBar.css */
.progress-bar-container {
  width: 100%;
  background-color: #e0e0df;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 0;
}

.progress-bar {
  height: 10px;
  text-align: center;
  line-height: 30px;
  color: white;
  transition: width 1s ease-in-out; /* Add transition for animation */
}
