.sponsor-div {
  height: 650px;
  //   background: url("../../assets/images/landing.webp");
  background: url("../../assets/images/landing2.webp");
  margin-top: -800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 120px;
  h1 {
    font-size: 60px;
    @media screen and (max-width: 820px) {
      font-size: 20px;
    }
  }
}
.adv {
  margin-top: 40px;
  a {
    color: #dc727c;
  }
}
.heading {
  text-shadow: 5px 5px 10px black;
  color: #def2fa;
}
