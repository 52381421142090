.upload__image-wrapper {
  button {
    border: none;
    background: #fff;
    font-family: SoraR;
    font-size: 13px;
    padding: 0;
    
    span {
      color: green !important;
      font-weight: bold;
    }
  }
}
