.app-nav {
  height: 800px;
  position: relative;
  overflow: hidden;

}
@media print {
  .app-nav{display: none !important;}

}
li .list-inline-item{
  border-right: 1px solid aliceblue !important;
}
.aside-section {
  top: 0;
  bottom: 0;
  position: absolute;
}

.aside-left {
  display: none;
  width: 40%;
  left: 0;
  background: #dc727c;
  z-index: 230;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  transition: transform 0.9s ease-in-out;
  overflow:hidden;
}

.aside-right {
  width: 100%;
  right: 0;
  background-color: #def2fa;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  transition: transform 0.9s ease-in-out;
  z-index: 230;
}

.aside-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 150px;
  text-align: left;
  padding-left: 50px;
}

.aside-content {
  //   margin-top: 150px;
  padding: 0 40px;
  position: relative;
  color: white;
  text-align: center;
}

.aside-list li {
  margin-bottom: 20px;
}

.aside-anchor::after {
  content: "";
  position: absolute;
  bottom: 0;
  background-color: #ff5964;
  left: 0;
  right: 0;
  height: 6px;
  border-radius: 6px;
}

.aside-anchor::before {
  border-radius: 3px;
  content: "";
  position: absolute;
  bottom: 0;
  background-color: rgb(9, 1, 1);
  left: 0;
  height: 6px;
  z-index: 1;
  width: 50%;
  -webkit-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}

.aside-anchor:hover:before {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

.aside-anchor {
  padding-bottom: 7px;
  color: rgb(14, 14, 14);
  text-decoration: none;
  font-size: 30px;
  position: relative;
  font-weight: 500;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked ~ aside .aside-left {
  transform: translateY(0%);
}

input[type="checkbox"]:checked ~ aside .aside-right {
  transform: translateX(0%);
}

input[type="checkbox"]:checked ~ label .bar {
  background-color: #fff;
}

input[type="checkbox"]:checked ~ label .top {
  -webkit-transform: translateY(0px) rotateZ(45deg);
  -moz-transform: translateY(0px) rotateZ(45deg);
  -ms-transform: translateY(0px) rotateZ(45deg);
  -o-transform: translateY(0px) rotateZ(45deg);
  transform: translateY(0px) rotateZ(45deg);
}

input[type="checkbox"]:checked ~ label .bottom {
  -webkit-transform: translateY(-15px) rotateZ(-45deg);
  -moz-transform: translateY(-15px) rotateZ(-45deg);
  -ms-transform: translateY(-15px) rotateZ(-45deg);
  -o-transform: translateY(-15px) rotateZ(-45deg);
  transform: translateY(-15px) rotateZ(-45deg);
}

input[type="checkbox"]:checked ~ label .middle {
  width: 0;
}

.middle {
  margin: 0 auto;
}

label {
  // top: 40px;
  display: inline-block;
  padding: 10px 10px;
  background-color: transparent;
  cursor: pointer;
  margin: 10px;
  z-index: 3;
  position: fixed;
}

.bar {
  display: block;
  background-color: #fff;
  width: 30px;
  height: 3px;
  border-radius: 5px;
  margin: 5px auto;
  transition: background-color 0.4s ease-in, transform 0.4s ease-in,
    width 0.4s ease-in;
}

@media (min-width: 992px) {
  h1 {
    font-size: 40px;
  }
  .aside-left {
    display: block;
  }

  .aside-right {
    width: 60%;
  }
}
.tog {
  height: 50px;
  width: 50px;
  border: solid 1px #be3520;
  background: #be3520;
  border-radius: 50%;
  z-index: 231;
  position: relative;
  margin-top: 40px;
  @media screen and (max-width: 820px) {
    margin-top: 10px;
  }
}
.app-logo {
  width: 70%;
  @media screen and (max-width: 820px) {
    width: 20%;
  }
}
.nav-inner{
  position:relative;
  z-index:20
}