.contact-div {
  height: 600px;
  //   background: url("../../assets/images/landing.webp");
  background: url("../../assets/images/landing1.jpeg");
  margin-top: -800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 120px;
  h1 {
    font-size: 60px;
    @media screen and (max-width: 820px) {
      font-size: 20px;
    }
  }
}
.contact-form {
  margin-top: 150px;
  input,
  textarea {
    width: 100%;
    padding: 10px 20px;
    margin-top: 20px;
    border: solid 1px #e7e7e7;
    border-radius: 5px;
    font-family: SoraM;
  }
  textarea {
    min-height: 200px;
  }
  a {
    color: #000;
    line-height: 40px;
    text-decoration: none;
    &:hover {
      color: #dc727c;
      text-decoration: underline;
    }
  }
}
.media-icons {
  color: #dc727c;
  margin-right: 20px;
}
.heading {
  text-shadow: 5px 5px 10px black;
  h1 {
    font-size: 65px !important;
  }
}
