.payment-form {
  border: solid 1px #e7e7e7;
  border-radius: 20px;
  padding: 30px 20px;
  text-align: left;
  input {
    width: 100%;
    border: solid 1px #e7e7e7;
    padding: 10px;
    border-radius: 7px;
    position: relative;
    z-index: 20;
    font-family: soraM;
  }
  h6 {
    margin-top: 15px;
  }
  small {
    color: grey;
    font-size: 12px;
  }
  h4 {
    margin-top: 30px;
  }
  button {
    width: 100%;
    border-radius: 20px;
    padding: 10px;
    margin-top: 30px;
    background: #2c60cc;
    color: #fff;
    border: solid 1px #2c60cc;
    font-family: soraM;
    position:relative !important;
    z-index:10000 !important
  }
}
.payment-div {
  margin-top: -700px;
  z-index: 100;
  position: relative;
}
