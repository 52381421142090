.accord-faq {
  // margin-top: 100px;
  @media screen and (max-width:820px){
    margin-top:100px;
  }
  div + div {
    clear: both;
  }

  p {
    line-height: 1.4em;
    color: #9e9e9e;
  }

  .faq-title {
    font-size: 1.5em;
    margin: 0.4em 0;

    @media screen and (max-width: 820px) {
      font-size: 1em;
    }
  }

  div.seperator {
    width: 150px;
    background-color: #7bd6f5;
    height: 0.17em;
    margin-left: 0.8em;
  }

  .faq-list > div {
    border-bottom: 0.07em solid #ededed;
    padding: 1.5em 0em;
  }

  .faq-list > div:last-child {
    border: unset;
  }

  details > summary {
    list-style: none;
  }
  details > summary::-webkit-details-marker {
    display: none;
  }

  summary {
    font-size: 1;
    font-weight: bold;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s ease;
  }

  summary:hover {
    color: #7bd6f5;
  }

  details[open] summary ~ * {
    animation: sweep 0.5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-left: -10px;
    }
    100% {
      opacity: 1;
      margin-left: 55px;
    }
  }

  details[open] summary {
    color: #7bd6f5;
  }

  details[open] p {
    border-left: 2px solid #7bd6f5;
    margin-left: 55px;
    padding-left: 25px;
    opacity: 100;
    transition: all 3s ease;
  }

  details[open] summary:after {
    content: "-";
    font-size: 3.2em;
    margin: -33px 0.35em 0 0;
    font-weight: 200;
  }

  .faq-body {
    // width: 70em;
    // margin: 4em auto;
    box-shadow: 0px 0px 16px #5b5b5b;
    border-radius: 0.2em;
    padding: 2em;
    background-color: #fff;
    @media screen and (max-width: 820px) {
      padding: 1em;
    }
  }

  .faq-list {
    // width: 55em;
    margin: 1em auto;
    padding: 2em 0;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  summary:after {
    background: transparent;
    border-radius: 0.3em;
    content: "+";
    color: #7bd6f5;
    float: left;
    font-size: 1.8em;
    font-weight: bold;
    margin: -0.3em 0.65em 0 0;
    padding: 0;
    text-align: center;
    width: 25px;
  }
}
