.activities {
  display: flex;
  margin-top: 10px;
  p {
    font-size: 12px;
    font-family: montR;
    opacity: 0.5;
  }
  h4 {
    font-size: 14px;
    margin-bottom: 0;
  }
  .alphabet {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 15px;
    text-align: center !important;
    @media screen and (max-width: 820px) {
      width: 44px;
    }
    p {
      font-size: 20px;
      color: #fff;
      opacity: 1;
      font-family: montSB;
    }
  }
  small {
    color: #191919;
    opacity: 0.5;
    font-size: 10px;
    font-family: MontR;
  }
  sup {
    color: #12b76a;
    font-size: 25px;
    font-family: montSB;
  }
}
