.dash-container {
  padding: 10px;
  @media screen and (max-width:820px){
    padding:0;
   
  }
  border-left: solid 1px #e7e7e7;
  .dropdown-toggle {
    background: transparent;
    border: none;
  }
  .dropdown-menu {
    padding: 20px;
  }
}

.dash-div {
  text-align: left;
  background: #f5f6ff;
  padding: 30px 25px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  height: fit-content;
  margin-top: -120px;
  @media screen and (max-width:820px) {
    padding: 30px 5px;
  }
  .stats {
    border: solid 1px #e7e7e7;
    height: 140px;
    border-radius: 20px;
    padding: 20px;

    background: #fff;
  }
}
.admin-top {
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background: #c2e3f6;
  height: 250px;
  padding: 40px;
  input {
    border-radius: 10px;
    width: 150%;
    padding: 7px 10px;
    border: none;
    font-family: montR;
  }
}
.reg-chart {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #1018281a;
  border: 1px solid #e4e7ec;
  border-radius: 10px;
  padding: 20px;
  height: 430px;
  margin-top: 30px;
  overflow: hidden scroll !important;
  @media screen and (max-width:820px){
    height:fit-content;
  }
}
.reg-chart::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.reg-chart {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.app-table {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #1018281a;
  border: 1px solid #e4e7ec;
  border-radius: 10px;
  padding: 20px;
  height: fit-content;
  margin-top: 30px;
  h5 {
    text-align: left !important;
    margin-bottom: 30px;
  }
}
.admin-nav {
  padding: 10px 5px;
  min-height: 800px;
  border-right: solid 1px #f7f5f5;
  box-shadow: 0px 3px 3px #00000029;
  // background: #c2e3f6;
  .dash-logo {
    height: 104px;
    border-bottom: solid 1px #f7f5f5;
  }
  a {
    color: #191919;
    text-decoration: none;

    font-family: montM;
    font-size: 16px;
    width: 100% !important;
    padding: 10px 90px 10px 10px;
    span {
      margin-right: 5px;
      font-size: 24px;
    }
  }
  .active {
    background: #f8e3e3;
    // padding: 18px 40px 18px 10px;
    color: #f0908d !important;
    font-weight: bold !important;
    border-radius: 10px;
    transition: 0.7s ease;
    font-family: montSB;
  }
  li {
    width: 100%;
    margin-top: 20px;
  }
  
}
.dashboard-btn {
  background: #fff;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  padding: 6px 15px;
  font-family: montB;
  &:hover {
    background: #f8e3e3;
    color: #f88884;
  }
}
.dash-inner{
  padding:0 20px;
  @media screen and (max-width:820px){
    padding:0 5px
  }
}

.offcanvas-btn{
  background: #c2e3f6;
  margin-bottom:0;
 margin:0 5px;
  // height: 240px;
  // border-bottom-right-radius:20px;
  @media screen and (min-width:820px){
    display:none;
  }
}
.menuu{
  @media screen and (max-width:820px){
    max-width:80%
  }
}