.home-div {
  height: 700px;
  //   background: url("../../assets/images/landing.webp");
  background: url("../../assets/images/baker.webp");
  margin-top: -800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 80px;
  h1 {
    font-family: nick;
    color: #c0e3f6;
    font-size: 80px;
    font-weight: bold;
  }
}
.about-div {
  margin-top: 100px;
}

.sponsors-div {
  margin-top: 80px;
  padding-top: 30px;
  padding-bottom: 30px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 5%,
    rgba(221, 221, 221, 1) 31%
  );
  .sponsors {
    padding-top: 10px;
    margin-top: 45px;
    height: 180px;
    background: #fff;
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%)
        var(--_p, 100%) / 300% no-repeat,
      #fff;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i, -1)));
    text-shadow: calc(var(--_i, -1) * 0.08em) -0.01em 0 var(--c),
      calc(var(--_i, -1) * -0.08em) 0.01em 2px #0004;
    outline-offset: 0.1em;
    transition: all 0.5s;
    &:hover {
      --_p: 0%;
      --_i: 1;
    }
  }
}

.accord {
  @media screen and (max-width: 820px) {
    margin-top: 30px;
  }
}

.host {
  @media screen and (max-width: 820px) {
    margin-top: 30px;
  }
}
.home-flier{
  margin-top:70px
}