.success-page-div {
  height: 500px;
  margin-top: -750px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #1018281a;
  border: 1px solid #e4e7ec;
  border-radius: 20px;
  padding-top: 50px;
  z-index: 221;
  position: relative;
  h5 {
    margin-top: 30px;
  }
}
